<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbBlogSection />
    <BlogSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbBlogSection from '../components/breadcrumb/breadcrumb-blog/breadcrumb-blog.vue'
import BlogSection from '../components/blog-section/blog-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'Blog',
  components: {
    HeaderSection,
    BreadcrumbBlogSection,
    BlogSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>