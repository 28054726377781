<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbExploreTwoSection />
    <ExploreThreeSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbExploreTwoSection from '../components/breadcrumb/breadcrumb-explore-two/breadcrumb-explore-two.vue'
import ExploreThreeSection from '../components/explore/explore-three-section/explore-three-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'ExploreTwo',
  components: {
    HeaderSection,
    BreadcrumbExploreTwoSection,
    ExploreThreeSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>