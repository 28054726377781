<template>
    <section class="popular-collections-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Intro -->
                    <div class="intro d-flex justify-content-between align-items-end m-0">
                        <div class="intro-content">
                            <span>Most Popular</span>
                            <h3 class="mt-3 mb-0">Popular Collections</h3>
                        </div>
                        <div class="intro-btn">
                            <a class="btn content-btn text-left" href="/explore-two">Explore More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row items">
                <div class="col-12 col-sm-6 col-lg-3 item">
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <a href="item-details">
                                <img class="card-img-top" src="assets/img/author_1.jpg" alt="">
                            </a>
                            <!-- Seller -->
                            <a class="seller" href="item-details">
                                <div class="seller-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_1.jpg" alt="">
                                </div>
                            </a>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <a href="item-details">
                                    <h5 class="mb-2">Virtual Worlds</h5>
                                </a>
                                <span>ERC-729</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 item">
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <a href="item-details">
                                <img class="card-img-top" src="assets/img/author_2.jpg" alt="">
                            </a>
                            <!-- Seller -->
                            <a class="seller" href="item-details">
                                <div class="seller-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_2.jpg" alt="">
                                </div>
                            </a>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <a href="item-details">
                                    <h5 class="mb-2">Digital Arts</h5>
                                </a>
                                <span>ERC-522</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 item">
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <a href="item-details">
                                <img class="card-img-top" src="assets/img/author_3.jpg" alt="">
                            </a>
                            <!-- Seller -->
                            <a class="seller" href="item-details">
                                <div class="seller-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_3.jpg" alt="">
                                </div>
                            </a>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <a href="item-details">
                                    <h5 class="mb-2">Sports</h5>
                                </a>
                                <span>ERC-495</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 item">
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <a href="item-details">
                                <img class="card-img-top" src="assets/img/author_4.jpg" alt="">
                            </a>
                            <!-- Seller -->
                            <a class="seller" href="item-details">
                                <div class="seller-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_4.jpg" alt="">
                                </div>
                            </a>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <a href="item-details">
                                    <h5 class="mb-2">Photography</h5>
                                </a>
                                <span>ERC-873</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 item">
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <a href="item-details">
                                <img class="card-img-top" src="assets/img/author_5.jpg" alt="">
                            </a>
                            <!-- Seller -->
                            <a class="seller" href="item-details">
                                <div class="seller-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_5.jpg" alt="">
                                </div>
                            </a>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <a href="item-details">
                                    <h5 class="mb-2">Trading Cards</h5>
                                </a>
                                <span>ERC-397</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 item">
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <a href="item-details">
                                <img class="card-img-top" src="assets/img/author_6.jpg" alt="">
                            </a>
                            <!-- Seller -->
                            <a class="seller" href="item-details">
                                <div class="seller-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_6.jpg" alt="">
                                </div>
                            </a>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <a href="item-details">
                                    <h5 class="mb-2">Walking On Air</h5>
                                </a>
                                <span>ERC-403</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 item">
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <a href="item-details">
                                <img class="card-img-top" src="assets/img/author_7.jpg" alt="">
                            </a>
                            <!-- Seller -->
                            <a class="seller" href="item-details">
                                <div class="seller-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_7.jpg" alt="">
                                </div>
                            </a>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <a href="item-details">
                                    <h5 class="mb-2">Domain Names</h5>
                                </a>
                                <span>ERC-687</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 item">
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <a href="item-details">
                                <img class="card-img-top" src="assets/img/author_8.jpg" alt="">
                            </a>
                            <!-- Seller -->
                            <a class="seller" href="item-details">
                                <div class="seller-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_8.jpg" alt="">
                                </div>
                            </a>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <a href="item-details">
                                    <h5 class="mb-2">Collectibles</h5>
                                </a>
                                <span>ERC-972</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>