<template>
    <section class="author-area explore-area popular-collections-area">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-12 col-md-4">
                    <!-- Author Profile -->
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <img class="card-img-top" src="assets/img/auction_2.jpg" alt="">
                            <!-- Author -->
                            <div class="author">
                                <div class="author-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_8.jpg" alt="">
                                </div>
                            </div>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <h5 class="mb-3">Artnox</h5>
                                <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="ZqpthncaYTsd0579hasfu00st">
                                    <div class="input-group-append">
                                    <button><i class="icon-docs"></i></button>
                                    </div>
                                </div>
                                <!-- Social Icons -->
                                <div class="social-icons d-flex justify-content-center my-3">
                                    <a class="facebook" target="_blank" href="https://www.facebook.com/">
                                        <i class="fab fa-facebook-f"></i>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a class="twitter" target="_blank" href="https://twitter.com/">
                                        <i class="fab fa-twitter"></i>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a class="telegram" target="_blank" href="https://telegram.org/">
                                        <i class="fab fa-telegram"></i>
                                        <i class="fab fa-telegram"></i>
                                    </a>
                                    <a class="discord" target="_blank" href="https://discord.com/">
                                        <i class="fab fa-discord"></i>
                                        <i class="fab fa-discord"></i>
                                    </a>
                                </div>
                                <a class="btn btn-bordered-white btn-smaller" href="login">Follow</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="row justify-content-center text-center mt-5 mt-lg-0">
                        <div class="col-12">
                            <!-- Explore Menu -->
                            <div class="explore-menu btn-group btn-group-toggle flex-wrap justify-content-center text-center mb-4" data-toggle="buttons">
                                <label class="btn active d-table text-uppercase p-2">
                                    <input type="radio" value="all" checked class="explore-btn">
                                    <span>All</span>
                                </label>
                                <label class="btn d-table text-uppercase p-2">
                                    <input type="radio" value="art" class="explore-btn">
                                    <span>Art</span>
                                </label>
                                <label class="btn d-table text-uppercase p-2">
                                    <input type="radio" value="music" class="explore-btn">
                                    <span>Music</span>
                                </label>
                                <label class="btn d-table text-uppercase p-2">
                                    <input type="radio" value="collectibles" class="explore-btn">
                                    <span>Collectibles</span>
                                </label>
                                <label class="btn d-table text-uppercase p-2">
                                    <input type="radio" value="sports" class="explore-btn">
                                    <span>Sports</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row items explore-items">
                        <div class="col-12 col-md-6 item explore-item" data-groups='["art","sports"]'>
                            <div class="card no-hover text-center">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_1.jpg" alt="">
                                    </a>
                                    <!-- Author -->
                                    <a class="author" href="authors">
                                        <div class="author-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar_1.jpg" alt="">
                                        </div>
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body mt-4">
                                        <a href="item-details">
                                            <h5 class="mb-2">Virtual Worlds</h5>
                                        </a>
                                        <span>ERC-729</span>
                                        <hr>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>4.37 BNB</span>
                                            <span><i class="icon-heart mr-2"></i>154</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 item explore-item" data-groups='["music"]'>
                            <div class="card no-hover text-center">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_2.jpg" alt="">
                                    </a>
                                    <!-- Author -->
                                    <a class="author" href="authors">
                                        <div class="author-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar_2.jpg" alt="">
                                        </div>
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body mt-4">
                                        <a href="item-details">
                                            <h5 class="mb-2">Walking On Air</h5>
                                        </a>
                                        <span>ERC-543</span>
                                        <hr>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>2.37 BNB</span>
                                            <span><i class="icon-heart mr-2"></i>74</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 item explore-item" data-groups='["music","collectibles"]'>
                            <div class="card no-hover text-center">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_3.jpg" alt="">
                                    </a>
                                    <!-- Author -->
                                    <a class="author" href="authors">
                                        <div class="author-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar_3.jpg" alt="">
                                        </div>
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body mt-4">
                                        <a href="item-details">
                                            <h5 class="mb-2">Trading Cards</h5>
                                        </a>
                                        <span>ERC-932</span>
                                        <hr>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>1.89 BNB</span>
                                            <span><i class="icon-heart mr-2"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 item explore-item" data-groups='["music","sports","art","collectibles"]'>
                            <div class="card no-hover text-center">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_4.jpg" alt="">
                                    </a>
                                    <!-- Author -->
                                    <a class="author" href="authors">
                                        <div class="author-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar_4.jpg" alt="">
                                        </div>
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body mt-4">
                                        <a href="item-details">
                                            <h5 class="mb-2">Sports</h5>
                                        </a>
                                        <span>ERC-250</span>
                                        <hr>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>0.74 BNB</span>
                                            <span><i class="icon-heart mr-2"></i>184</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 item explore-item" data-groups='["sports","art","music"]'>
                            <div class="card no-hover text-center">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_5.jpg" alt="">
                                    </a>
                                    <!-- Author -->
                                    <a class="author" href="authors">
                                        <div class="author-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar_5.jpg" alt="">
                                        </div>
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body mt-4">
                                        <a href="item-details">
                                            <h5 class="mb-2">Cartoon Heroes</h5>
                                        </a>
                                        <span>ERC-167</span>
                                        <hr>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>4.23 BNB</span>
                                            <span><i class="icon-heart mr-2"></i>126</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 item explore-item" data-groups='["art","sports"]'>
                            <div class="card no-hover text-center">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_6.jpg" alt="">
                                    </a>
                                    <!-- Author -->
                                    <a class="author" href="authors">
                                        <div class="author-thumb avatar-lg">
                                            <img class="rounded-circle" src="assets/img/avatar_6.jpg" alt="">
                                        </div>
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body mt-4">
                                        <a href="item-details">
                                            <h5 class="mb-2">Domain Names</h5>
                                        </a>
                                        <span>ERC-037</span>
                                        <hr>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>3.41 BNB</span>
                                            <span><i class="icon-heart mr-2"></i>23</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>