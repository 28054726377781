<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbItemDetailsSection />
    <ItemDetailsSection />
    <AuctionsThreeSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbItemDetailsSection from '../components/breadcrumb/breadcrumb-item-details/breadcrumb-item-details.vue'
import ItemDetailsSection from '../components/item-details-section/item-details-section.vue'
import AuctionsThreeSection from '../components/auctions/auctions-three-section/auctions-three-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'ItemDetails',
  components: {
    HeaderSection,
    BreadcrumbItemDetailsSection,
    ItemDetailsSection,
    AuctionsThreeSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>