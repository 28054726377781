<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbContactSection />
    <ContactSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbContactSection from '../components/breadcrumb/breadcrumb-contact/breadcrumb-contact.vue'
import ContactSection from '../components/contact-section/contact-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'Contact',
  components: {
    HeaderSection,
    BreadcrumbContactSection,
    ContactSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>