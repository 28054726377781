<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbCreateSection />
    <CreateSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbCreateSection from '../components/breadcrumb/breadcrumb-create/breadcrumb-create.vue'
import CreateSection from '../components/create-section/create-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'Create',
  components: {
    HeaderSection,
    BreadcrumbCreateSection,
    CreateSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>