import Vue from 'vue'
import Router from 'vue-router'

import ThemeOne from '@/themes/theme-one'
import ExploreOne from '@/themes/explore-one'
import ExploreTwo from '@/themes/explore-two'
import ExploreThree from '@/themes/explore-three'
import ExploreFour from '@/themes/explore-four'
import LiveAuctions from '@/themes/live-auctions'
import ItemDetails from '@/themes/item-details'
import Activity from '@/themes/activity'
import Authors from '@/themes/authors'
import Author from '@/themes/author'
import WalletConnect from '@/themes/wallet-connect'
import Create from '@/themes/create'
import Login from '@/themes/login'
import Signup from '@/themes/signup'
import Contact from '@/themes/contact'
import Blog from '@/themes/blog'
import BlogSingle from '@/themes/blog-single'
import HelpCenter from '@/themes/help-center'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'ThemeOne',
      component: ThemeOne
    },
    {
      path: '/explore-one',
      name: 'ExploreOne',
      component: ExploreOne
    },
    {
      path: '/explore-two',
      name: 'ExploreTwo',
      component: ExploreTwo
    },
    {
      path: '/explore-three',
      name: 'ExploreThree',
      component: ExploreThree
    },
    {
      path: '/explore-four',
      name: 'ExploreFour',
      component: ExploreFour
    },
    {
      path: '/auctions',
      name: 'LiveAuctions',
      component: LiveAuctions
    },
    {
      path: '/item-details',
      name: 'ItemDetails',
      component: ItemDetails
    },
    {
      path: '/activity',
      name: 'Activity',
      component: Activity
    },
    {
      path: '/authors',
      name: 'Authors',
      component: Authors
    },
    {
      path: '/author',
      name: 'Author',
      component: Author
    },
    {
      path: '/wallet-connect',
      name: 'WalletConnect',
      component: WalletConnect
    },
    {
      path: '/create',
      name: 'Create',
      component: Create
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/blog',
      name: 'Blog',
      component: Blog
    },
    {
      path: '/blog-single',
      name: 'BlogSingle',
      component: BlogSingle
    },
    {
      path: '/help-center',
      name: 'HelpCenter',
      component: HelpCenter
    }
  ]
})