<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbExploreOneSection />
    <ExploreTwoSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbExploreOneSection from '../components/breadcrumb/breadcrumb-explore-one/breadcrumb-explore-one.vue'
import ExploreTwoSection from '../components/explore/explore-two-section/explore-two-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'ExploreOne',
  components: {
    HeaderSection,
    BreadcrumbExploreOneSection,
    ExploreTwoSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>