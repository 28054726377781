<template>
  <div class="main">
    <HeaderSection />
    <HeroSection />
    <AuctionsOneSection />
    <TopSellerSection />
    <CollectionsSection />
    <ExploreOneSection />
    <WorkSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import HeroSection from '../components/hero-section/hero-section.vue'
import AuctionsOneSection from '../components/auctions/auctions-one-section/auctions-one-section.vue'
import TopSellerSection from '../components/top-seller-section/top-seller-section.vue'
import CollectionsSection from '../components/collections-section/collections-section.vue'
import ExploreOneSection from '../components/explore/explore-one-section/explore-one-section.vue'
import WorkSection from '../components/work-section/work-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'ThemeOne',
  components: {
    HeaderSection,
    HeroSection,
    AuctionsOneSection,
    TopSellerSection,
    CollectionsSection,
    ExploreOneSection,
    WorkSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>