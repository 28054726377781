<template>
    <section class="live-auctions-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Intro -->
                    <div class="intro d-flex justify-content-between align-items-end m-0">
                        <div class="intro-content">
                            <span>Auctions</span>
                            <h3 class="mt-3 mb-0">Live Auctions</h3>
                        </div>
                        <div class="intro-btn">
                            <a class="btn content-btn" href="auctions">View All</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="auctions-slides">
                <div class="swiper-container slider-mid items">
                    <div class="swiper-wrapper">
                        <!-- Single Slide -->
                        <div class="swiper-slide item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_1.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <div class="countdown-times mb-3">
                                            <div class='countdown d-flex justify-content-center' data-date="2022-07-09"></div>
                                        </div>
                                        <a href="item-details">
                                            <h5 class="mb-0">Virtual Worlds</h5>
                                        </a>
                                        <a class="seller d-flex align-items-center my-3" href="item-details">
                                            <img class="avatar-sm rounded-circle" src="assets/img/avatar_1.jpg" alt="">
                                            <span class="ml-2">@Richard</span>
                                        </a>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>1.5 BNB</span>
                                            <span>1 of 1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Single Slide -->
                        <div class="swiper-slide item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_2.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <div class="countdown-times mb-3">
                                            <div class='countdown d-flex justify-content-center' data-date="2022-08-19"></div>
                                        </div>
                                        <a href="item-details">
                                            <h5 class="mb-0">Collectibles</h5>
                                        </a>
                                        <a class="seller d-flex align-items-center my-3" href="item-details">
                                            <img class="avatar-sm rounded-circle" src="assets/img/avatar_2.jpg" alt="">
                                            <span class="ml-2">@JohnDeo</span>
                                        </a>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>2.7 BNB</span>
                                            <span>1 of 1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Single Slide -->
                        <div class="swiper-slide item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_3.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <div class="countdown-times mb-3">
                                            <div class='countdown d-flex justify-content-center' data-date="2022-07-15"></div>
                                        </div>
                                        <a href="item-details">
                                            <h5 class="mb-0">Arts</h5>
                                        </a>
                                        <a class="seller d-flex align-items-center my-3" href="item-details">
                                            <img class="avatar-sm rounded-circle" src="assets/img/avatar_3.jpg" alt="">
                                            <span class="ml-2">@MKHblots</span>
                                        </a>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>2.3 BNB</span>
                                            <span>1 of 1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Single Slide -->
                        <div class="swiper-slide item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_4.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <div class="countdown-times mb-3">
                                            <div class='countdown d-flex justify-content-center' data-date="2022-07-29"></div>
                                        </div>
                                        <a href="item-details">
                                            <h5 class="mb-0">Robotic Arts</h5>
                                        </a>
                                        <a class="seller d-flex align-items-center my-3" href="item-details">
                                            <img class="avatar-sm rounded-circle" src="assets/img/avatar_4.jpg" alt="">
                                            <span class="ml-2">@RioArham</span>
                                        </a>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>1.8 BNB</span>
                                            <span>1 of 1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Single Slide -->
                        <div class="swiper-slide item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_5.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <div class="countdown-times mb-3">
                                            <div class='countdown d-flex justify-content-center' data-date="2022-08-24"></div>
                                        </div>
                                        <a href="item-details">
                                            <h5 class="mb-0">Design Illusions</h5>
                                        </a>
                                        <a class="seller d-flex align-items-center my-3" href="item-details">
                                            <img class="avatar-sm rounded-circle" src="assets/img/avatar_5.jpg" alt="">
                                            <span class="ml-2">@ArtNox</span>
                                        </a>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>1.7 BNB</span>
                                            <span>1 of 1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Single Slide -->
                        <div class="swiper-slide item">
                            <div class="card">
                                <div class="image-over">
                                    <a href="item-details">
                                        <img class="card-img-top" src="assets/img/auction_6.jpg" alt="">
                                    </a>
                                </div>
                                <!-- Card Caption -->
                                <div class="card-caption col-12 p-0">
                                    <!-- Card Body -->
                                    <div class="card-body">
                                        <div class="countdown-times mb-3">
                                            <div class='countdown d-flex justify-content-center' data-date="2022-07-30"></div>
                                        </div>
                                        <a href="item-details">
                                            <h5 class="mb-0">Design Illusions</h5>
                                        </a>
                                        <a class="seller d-flex align-items-center my-3" href="item-details">
                                            <img class="avatar-sm rounded-circle" src="assets/img/avatar_6.jpg" alt="">
                                            <span class="ml-2">@ArtNox</span>
                                        </a>
                                        <div class="card-bottom d-flex justify-content-between">
                                            <span>1.7 BNB</span>
                                            <span>1 of 1</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>