<template>
    <section class="help-center-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-7">
                    <!-- Intro -->
                    <div class="intro text-center">
                        <span>Help Center</span>
                        <h3 class="mt-3 mb-0">How can we help you?</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center items">
                <div class="col-12 col-md-6 col-lg-4 item">
                    <!-- Help Card -->
                    <div class="card help-card">
                        <a class="d-block text-center" href="contact">
                            <i class="icon icon-plane text-effect"></i>
                            <h4>Getting Started</h4>
                            <p>Learn how to create an account, set up your wallet, and what you can do on NetStorm</p>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 item">
                    <!-- Help Card -->
                    <div class="card help-card">
                        <a class="d-block text-center" href="contact">
                            <i class="icon icon-note text-effect"></i>
                            <h4>Creating</h4>
                            <p>Learn how to create your very first NFT and how to create your NFT collections so you can begin selling and sharing</p>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 item">
                    <!-- Help Card -->
                    <div class="card help-card">
                        <a class="d-block text-center" href="contact">
                            <i class="icon icon-handbag text-effect"></i>
                            <h4>Buying</h4>
                            <p>Learn how to purchase your first NFT and understand gas fees and what's gas free on NetStorm</p>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 item">
                    <!-- Help Card -->
                    <div class="card help-card">
                        <a class="d-block text-center" href="contact">
                            <i class="icon icon-chart text-effect"></i>
                            <h4>Selling</h4>
                            <p>Learn how list your NFTs for sale and understand the different ways to list your NFTs</p>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 item">
                    <!-- Help Card -->
                    <div class="card help-card">
                        <a class="d-block text-center" href="contact">
                            <i class="icon icon-link text-effect"></i>
                            <h4>Partners</h4>
                            <p>Learn how you can partner with us to showcase your NFT drops</p>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 item">
                    <!-- Help Card -->
                    <div class="card help-card">
                        <a class="d-block text-center" href="contact">
                            <i class="icon icon-flag text-effect"></i>
                            <h4>Developers</h4>
                            <p>Learn how you can develop with NetStorm &amp; sell them on Marketplace</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>