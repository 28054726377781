<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbAuthorSection />
    <AuthorSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbAuthorSection from '../components/breadcrumb/breadcrumb-author/breadcrumb-author.vue'
import AuthorSection from '../components/author-section/author-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'Author',
  components: {
    HeaderSection,
    BreadcrumbAuthorSection,
    AuthorSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>