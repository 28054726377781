<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbSignupSection />
    <SignupSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbSignupSection from '../components/breadcrumb/breadcrumb-signup/breadcrumb-signup.vue'
import SignupSection from '../components/signup-section/signup-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'Signup',
  components: {
    HeaderSection,
    BreadcrumbSignupSection,
    SignupSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>