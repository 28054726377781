<template>
    <header id="header">
        <!-- Navbar -->
        <nav data-aos="zoom-out" data-aos-delay="800" class="navbar navbar-expand">
            <div class="container header">
                <!-- Navbar Brand-->
                <a class="navbar-brand" href="/">
                    <img class="navbar-brand-sticky" src="assets/img/logo.png" alt="sticky brand-logo">
                </a>
                <div class="ml-auto"></div>
                <!-- Navbar -->
                <ul class="navbar-nav items mx-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link" href="/">Home</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link" href="#">Explore <i class="fas fa-angle-down ml-1"></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="/explore-one" class="nav-link">Explore Style 1</a></li>
                            <li class="nav-item"><a href="/explore-two" class="nav-link">Explore Style 2</a></li>
                            <li class="nav-item"><a href="/explore-three" class="nav-link">Explore Style 3</a></li>
                            <li class="nav-item"><a href="/explore-four" class="nav-link">Explore Style 4</a></li>
                            <li class="nav-item"><a href="/auctions" class="nav-link">Live Auctions</a></li>
                            <li class="nav-item"><a href="/item-details" class="nav-link">Item Details</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="/activity" class="nav-link">Activity</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link" href="#">Community <i class="fas fa-angle-down ml-1"></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="/blog" class="nav-link">Blog</a></li>
                            <li class="nav-item"><a href="/blog-single" class="nav-link">Blog Single</a></li>
                            <li class="nav-item"><a href="/help-center" class="nav-link">Help Center</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link" href="#">Pages <i class="fas fa-angle-down ml-1"></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="/authors" class="nav-link">Authors</a></li>
                            <li class="nav-item"><a href="/author" class="nav-link">Author</a></li>
                            <li class="nav-item"><a href="/wallet-connect" class="nav-link">Wallet Connect</a></li>
                            <li class="nav-item"><a href="/create" class="nav-link">Create</a></li>
                            <li class="nav-item"><a href="/login" class="nav-link">Login</a></li>
                            <li class="nav-item"><a href="/signup" class="nav-link">Signup</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="/contact" class="nav-link">Contact</a>
                    </li>
                </ul>
                <!-- Navbar Icons -->
                <ul class="navbar-nav icons">
                    <li class="nav-item">
                        <a href="#" class="nav-link" data-toggle="modal" data-target="#search">
                            <i class="fas fa-search"></i>
                        </a>
                    </li>
                </ul>

                <!-- Navbar Toggler -->
                <ul class="navbar-nav toggle">
                    <li class="nav-item">
                        <a href="#" class="nav-link" data-toggle="modal" data-target="#menu">
                            <i class="fas fa-bars toggle-icon m-0"></i>
                        </a>
                    </li>
                </ul>

                <!-- Navbar Action Button -->
                <ul class="navbar-nav action">
                    <li class="nav-item ml-3">
                        <a href="/wallet-connect" class="btn ml-lg-auto btn-bordered-white"><i class="icon-wallet mr-md-2"></i>Wallet Connect</a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
export default {

}
</script>

<style>

</style>