<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbWalletConnectSection />
    <WalletSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbWalletConnectSection from '../components/breadcrumb/breadcrumb-wallet-connect/breadcrumb-wallet-connect.vue'
import WalletSection from '../components/wallet-section/wallet-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'WalletConnect',
  components: {
    HeaderSection,
    BreadcrumbWalletConnectSection,
    WalletSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>