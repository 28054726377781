<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbAuthorsSection />
    <AuthorsSection />
    <TopSellerTwoSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbAuthorsSection from '../components/breadcrumb/breadcrumb-authors/breadcrumb-authors.vue'
import AuthorsSection from '../components/authors-section/authors-section.vue'
import TopSellerTwoSection from '../components/top-seller-two-section/top-seller-two-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'Authors',
  components: {
    HeaderSection,
    BreadcrumbAuthorsSection,
    AuthorsSection,
    TopSellerTwoSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>