<template>
    <section class="blog-area load-more">
        <div class="container">
            <div class="row items">
                <div class="col-12 col-md-4 item">
                    <!-- Single Blog -->
                    <div class="card blog-card">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a href="blog-single"><img src="assets/img/blog_1.jpg" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content">
                            <!-- Meta Info -->
                            <ul class="meta-info d-flex justify-content-between list-unstyled mt-4">
                                <li>By <a href="author">Junaid Hasan</a></li>
                                <li><a href="blog-single">Feb 19, 2022</a></li>
                            </ul>
                            <!-- Blog Title -->
                            <a href="blog-single">
                                <h4>Decentralizing NFT metadata on NetStorm</h4>
                            </a>
                            <p>Lorem ipsum dolor, sit voluptatem consectetur adipisicing elit.</p>
                            <!-- Blog Button -->
                            <a class="btn content-btn" href="blog-single">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 item">
                    <!-- Single Blog -->
                    <div class="card blog-card">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a href="blog-single"><img src="assets/img/blog_2.jpg" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content">
                            <!-- Meta Info -->
                            <ul class="meta-info d-flex justify-content-between list-unstyled mt-4">
                                <li>By <a href="author">Junaid Hasan</a></li>
                                <li><a href="blog-single">Feb 19, 2022</a></li>
                            </ul>
                            <!-- Blog Title -->
                            <a href="blog-single">
                                <h4>The Lost Art Of Photography</h4>
                            </a>
                            <p>Lorem ipsum dolor, sit voluptatem consectetur adipisicing elit.</p>
                            <!-- Blog Button -->
                            <a class="btn content-btn" href="blog-single">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 item">
                    <!-- Single Blog -->
                    <div class="card blog-card">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a href="blog-single"><img src="assets/img/blog_3.jpg" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content">
                            <!-- Meta Info -->
                            <ul class="meta-info d-flex justify-content-between list-unstyled mt-4">
                                <li>By <a href="author">Sara Scotch</a></li>
                                <li><a href="blog-single">Jul 17, 2022</a></li>
                            </ul>
                            <!-- Blog Title -->
                            <a href="blog-single">
                                <h4>Save Thousands Of Lives Through This NFT</h4>
                            </a>
                            <p>Lorem ipsum dolor, sit voluptatem consectetur adipisicing elit.</p>
                            <!-- Blog Button -->
                            <a class="btn content-btn" href="blog-single">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 item">
                    <!-- Single Blog -->
                    <div class="card blog-card">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a href="blog-single"><img src="assets/img/blog_4.jpg" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content">
                            <!-- Meta Info -->
                            <ul class="meta-info d-flex justify-content-between list-unstyled mt-4">
                                <li>By <a href="author">John Deo</a></li>
                                <li><a href="blog-single">Aug 22, 2022</a></li>
                            </ul>
                            <!-- Blog Title -->
                            <a href="blog-single">
                                <h4>Golden State announce legacy NFT collection</h4>
                            </a>
                            <p>Lorem ipsum dolor, sit voluptatem consectetur adipisicing elit.</p>
                            <!-- Blog Button -->
                            <a class="btn content-btn" href="blog-single">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 item">
                    <!-- Single Blog -->
                    <div class="card blog-card">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a href="blog-single"><img src="assets/img/blog_5.jpg" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content">
                            <!-- Meta Info -->
                            <ul class="meta-info d-flex justify-content-between list-unstyled mt-4">
                                <li>By <a href="author">Yasmin Akter</a></li>
                                <li><a href="blog-single">Mar 19, 2022</a></li>
                            </ul>
                            <!-- Blog Title -->
                            <a href="blog-single">
                                <h4>Control Your Own Top Level Domain on the DNS & ENS</h4>
                            </a>
                            <p>Lorem ipsum dolor, sit voluptatem consectetur adipisicing elit.</p>
                            <!-- Blog Button -->
                            <a class="btn content-btn" href="blog-single">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 item">
                    <!-- Single Blog -->
                    <div class="card blog-card">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a href="blog-single"><img src="assets/img/blog_6.jpg" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content">
                            <!-- Meta Info -->
                            <ul class="meta-info d-flex justify-content-between list-unstyled mt-4">
                                <li>By <a href="author">Arham</a></li>
                                <li><a href="blog-single">Jan 31, 2022</a></li>
                            </ul>
                            <!-- Blog Title -->
                            <a href="blog-single">
                                <h4>Klaytn NFTs are coming to NetStorm</h4>
                            </a>
                            <p>Lorem ipsum dolor, sit voluptatem consectetur adipisicing elit.</p>
                            <!-- Blog Button -->
                            <a class="btn content-btn" href="blog-single">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 item">
                    <!-- Single Blog -->
                    <div class="card blog-card">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a href="blog-single"><img src="assets/img/blog_7.jpg" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content">
                            <!-- Meta Info -->
                            <ul class="meta-info d-flex justify-content-between list-unstyled mt-4">
                                <li>By <a href="author">Artnox</a></li>
                                <li><a href="blog-single">Mar 05, 2022</a></li>
                            </ul>
                            <!-- Blog Title -->
                            <a href="blog-single">
                                <h4>NetStorm raises $12M to scale the largest marketplace for NFTs</h4>
                            </a>
                            <p>Lorem ipsum dolor, sit voluptatem consectetur adipisicing elit.</p>
                            <!-- Blog Button -->
                            <a class="btn content-btn" href="blog-single">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 item">
                    <!-- Single Blog -->
                    <div class="card blog-card">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a href="blog-single"><img src="assets/img/blog_8.jpg" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content">
                            <!-- Meta Info -->
                            <ul class="meta-info d-flex justify-content-between list-unstyled mt-4">
                                <li>By <a href="author">William</a></li>
                                <li><a href="blog-single">May 12, 2022</a></li>
                            </ul>
                            <!-- Blog Title -->
                            <a href="blog-single">
                                <h4>How to safely purchase NFTs on NetStorm</h4>
                            </a>
                            <p>Lorem ipsum dolor, sit voluptatem consectetur adipisicing elit.</p>
                            <!-- Blog Button -->
                            <a class="btn content-btn" href="blog-single">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 item">
                    <!-- Single Blog -->
                    <div class="card blog-card">
                        <!-- Blog Thumb -->
                        <div class="blog-thumb">
                            <a href="blog-single"><img src="assets/img/blog_9.jpg" alt=""></a>
                        </div>
                        <!-- Blog Content -->
                        <div class="blog-content">
                            <!-- Meta Info -->
                            <ul class="meta-info d-flex justify-content-between list-unstyled mt-4">
                                <li>By <a href="author">Johnson</a></li>
                                <li><a href="blog-single">Sep 01, 2022</a></li>
                            </ul>
                            <!-- Blog Title -->
                            <a href="blog-single">
                                <h4>The beginner’s guide to creating & selling digital art NFTs</h4>
                            </a>
                            <p>Lorem ipsum dolor, sit voluptatem consectetur adipisicing elit.</p>
                            <!-- Blog Button -->
                            <a class="btn content-btn" href="blog-single">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <a id="load-btn" class="btn btn-bordered-white mt-5" href="#">Load More</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>