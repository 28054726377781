<template>
    <section class="author-area">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-12 col-md-4">
                    <!-- Author Profile -->
                    <div class="card no-hover text-center">
                        <div class="image-over">
                            <img class="card-img-top" src="assets/img/auction_2.jpg" alt="">
                            <!-- Author -->
                            <div class="author">
                                <div class="author-thumb avatar-lg">
                                    <img class="rounded-circle" src="assets/img/avatar_8.jpg" alt="">
                                </div>
                            </div>
                        </div>
                        <!-- Card Caption -->
                        <div class="card-caption col-12 p-0">
                            <!-- Card Body -->
                            <div class="card-body mt-4">
                                <h5 class="mb-3">Artnox</h5>
                                <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="ZqpthncaYTsd0579hasfu00st">
                                    <div class="input-group-append">
                                    <button><i class="icon-docs"></i></button>
                                    </div>
                                </div>
                                <!-- Social Icons -->
                                <div class="social-icons d-flex justify-content-center my-3">
                                    <a class="facebook" target="_blank" href="https://www.facebook.com/">
                                        <i class="fab fa-facebook-f"></i>
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a class="twitter" target="_blank" href="https://twitter.com/">
                                        <i class="fab fa-twitter"></i>
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a class="telegram" target="_blank" href="https://telegram.org/">
                                        <i class="fab fa-telegram"></i>
                                        <i class="fab fa-telegram"></i>
                                    </a>
                                    <a class="discord" target="_blank" href="https://discord.com/">
                                        <i class="fab fa-discord"></i>
                                        <i class="fab fa-discord"></i>
                                    </a>
                                </div>
                                <a class="btn btn-bordered-white btn-smaller" href="login">Follow</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7">
                    <!-- Intro -->
                    <div class="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                        <div class="intro-content">
                            <span>Get Started</span>
                            <h3 class="mt-3 mb-0">Create Item</h3>
                        </div>
                    </div>
                    <!-- Item Form -->
                    <form class="item-form card no-hover">
                        <div class="row">
                            <div class="col-12">
                                <div class="input-group form-group">
                                    <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="inputGroupFile01">
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mt-3">
                                    <input type="text" class="form-control" name="name" placeholder="Item Name" required="required">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control" name="textarea" placeholder="Description" cols="30" rows="3"></textarea>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="price" placeholder="Item Price" required="required">
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="royality" placeholder="Royality" required="required">
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Size" required="required">
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="copies" placeholder="No of Copies" required="required">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mt-3">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked>
                                        <label class="form-check-label" for="inlineRadio1">Put on Sale</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                        <label class="form-check-label" for="inlineRadio2">Instant Sale Price</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
                                        <label class="form-check-label" for="inlineRadio3">Unlock Purchased</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn w-100 mt-3 mt-sm-4" type="submit">Create Item</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>