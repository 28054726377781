<template>
    <section class="single featured post-details">
        <div class="container">
            <div class="row">
                <!-- Main -->
                <div class="col-12 col-lg-8 p-0">
                    <div class="row">
                        <div class="col-12 align-self-center">
                            <!-- Image -->
                            <div class="blog-thumb">
                                <img class="w-100" src="assets/img/blog_single.jpg" alt="">
                            </div>
                            <h2 class="featured ml-0">The beginner’s guide to creating & selling digital art NFTs</h2>
                            <p>Far far away, behind the word mountains, far from the countries Vokalia and coast, there live the blind texts. Separated they live in right at the coast of the Semantics, a large language ocean coast of the Semantics, a large language ocean.</p>
                            <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quam tortor, ultrices accumsan mauris eget, pulvinar tincidunt erat. Sed nisi nisi, rutrum sit amet elit.</blockquote>
                            <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. There live the blind texts. Separated they live in right at the coast, a large language ocean. Sed nisi nisi, amet, consectetur adipiscing rutrum sit amet elit.</p>
                            <p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</p>

                            <!-- Post Holder -->
                            <ul class="mb-5 post-holder">
                                <li class="post-meta-item">
                                    <div class="date">
                                        <span class="posted-on">Posted On <a href="#" rel="bookmark"><time class="entry-date published updated" datetime="2018-11-01T06:18:46+00:00">Sep 12, 2022</time></a></span>
                                    </div>
                                </li>
                            </ul>

                            <!-- Comments -->
                            <h3 class="comments-reply-text mb-3">Leave a Reply</h3>
                            <p class="mt-3">Sed mauris nulla, tempor eu est vel, dapibus hendrerit mauris.</p>
                            <form>
                                <fieldset>
                                    <div class="row">
                                        <div class="col-12 col-lg-6 input-group p-0 pr-lg-3 mb-3">
                                            <input type="text" name="name" data-minlength="3" placeholder="Name" required>
                                        </div>
                                        <div class="col-12 col-lg-6 input-group p-0 mb-3">
                                            <input type="email" name="email" data-minlength="3" placeholder="Email" required>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 input-group p-0 mb-3">
                                            <textarea name="message" data-minlength="3" rows="4" placeholder="Message" required></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12 input-group p-0">
                                        <a class="btn btn-bordered-white text-white">POST COMMENT<i class="icon-login ml-2"></i></a>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- Sidebar -->
                <aside class="col-12 col-lg-4 pl-lg-5 p-0 float-right sidebar">
                    <div class="row">
                        <div class="col-12 align-self-center text-left">

                            <!-- Widget [categories] -->
                            <div class="item widget-categories">
                                <h4 class="title">Categories</h4>
                                <ul class="list-group list-group-flush">

                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <a href="/blog">Arts</a>
                                        <span class="badge circle">23</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <a href="/blog">Photography</a>
                                        <span class="badge circle">15</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <a href="/blog">Sports</a>
                                        <span class="badge circle">12</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <a href="/blog">Collectibles</a>
                                        <span class="badge circle">29</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <a href="/blog">Domains</a>
                                        <span class="badge circle">16</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <a href="/blog">Technology</a>
                                        <span class="badge circle">13</span>
                                    </li>

                                </ul>
                            </div>

                            <!-- Widget [tags] -->
                            <div class="item widget-tags">
                                <h4 class="title">Popular Tags</h4>

                                <a href="/blog" class="badge tag">Bitcoin</a>
                                <a href="/blog" class="badge tag">NFT</a>
                                <a href="/blog" class="badge tag">Bids</a>
                                <a href="/blog" class="badge tag">Crypto</a>
                                <a href="/blog" class="badge tag">Digital</a>
                                <a href="/blog" class="badge tag">Arts</a>
                                <a href="/blog" class="badge tag">Marketplace</a>
                                <a href="/blog" class="badge tag">Token</a>
                                <a href="/blog" class="badge tag">Wallet</a>

                            </div>

                            <!-- Widget [share-this] -->
                            <div class="item widget-share-this">
                                <h4 class="title">Share This</h4>
                                <ul class="navbar-nav social share-list">

                                    <li class="nav-item">
                                        <a target="_blank" href="https://www.instagram.com/" class="nav-link"><i class="icon-social-instagram ml-0"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a target="_blank" href="https://www.facebook.com/" class="nav-link"><i class="icon-social-facebook"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a target="_blank" href="https://twitter.com/" class="nav-link"><i class="icon-social-twitter"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a target="_blank" href="https://www.linkedin.com/" class="nav-link"><i class="icon-social-linkedin"></i></a>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>