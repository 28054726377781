<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbHelpCenterSection />
    <HelpCenterSection />
    <FaqSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbHelpCenterSection from '../components/breadcrumb/breadcrumb-help-center/breadcrumb-help-center.vue'
import HelpCenterSection from '../components/help-center-section/help-center-section.vue'
import FaqSection from '../components/faq-section/faq-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'HelpCenter',
  components: {
    HeaderSection,
    BreadcrumbHelpCenterSection,
    HelpCenterSection,
    FaqSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>