<template>
    <footer class="footer-area">
        <!-- Footer Top -->
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3 res-margin">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Logo -->
                            <a class="navbar-brand" href="/">
                                <img src="assets/img/logo.png" alt="">
                            </a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis non, fugit totam vel laboriosam vitae.</p>
                            <!-- Social Icons -->
                            <div class="social-icons d-flex">
                                <a class="facebook" target="_blank" href="https://www.facebook.com/">
                                    <i class="fab fa-facebook-f"></i>
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a class="twitter" target="_blank" href="https://twitter.com/">
                                    <i class="fab fa-twitter"></i>
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a class="telegram" target="_blank" href="https://telegram.org/">
                                    <i class="fab fa-telegram"></i>
                                    <i class="fab fa-telegram"></i>
                                </a>
                                <a class="discord" target="_blank" href="https://discord.com/">
                                    <i class="fab fa-discord"></i>
                                    <i class="fab fa-discord"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 res-margin">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h4 class="footer-title">Useful Links</h4>
                            <ul>
                                <li><a href="explore-two">All NFTs</a></li>
                                <li><a href="signup">How It Works</a></li>
                                <li><a href="create">Create</a></li>
                                <li><a href="explore-one">Explore</a></li>
                                <li><a href="contact">Privacy &amp; Terms</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3 res-margin">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h4 class="footer-title">Community</h4>
                            <ul>
                                <li><a href="help-center">Help Center</a></li>
                                <li><a href="authors">Partners</a></li>
                                <li><a href="login">Suggestions</a></li>
                                <li><a href="blog">Blog</a></li>
                                <li><a href="contact">Newsletter</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h4 class="footer-title">Subscribe Us</h4>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form d-flex align-items-center">
                                <input type="email" class="form-control" placeholder="info@yourmail.com">
                                <button type="submit" class="btn"><i class="icon-paper-plane"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left">&copy;2022 NetStorm, All Rights Reserved.</div>
                            <!-- Copyright Right -->
                            <div class="copyright-right">Made with <i class="fas fa-heart"></i> By <a target="_blank" href="https://themeforest.net/user/theme_land">Themeland</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>