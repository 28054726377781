<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbLoginSection />
    <LoginSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbLoginSection from '../components/breadcrumb/breadcrumb-login/breadcrumb-login.vue'
import LoginSection from '../components/login-section/login-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'Login',
  components: {
    HeaderSection,
    BreadcrumbLoginSection,
    LoginSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>