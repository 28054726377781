<template>
  <div class="main">
    <HeaderSection />
    <BreadcrumbExploreThreeSection />
    <ExploreFourSection />
    <FooterSection />
    <ModalSearchSection />
    <ModalMenuSection />
    <ScrollupSection />
  </div>
</template>

<script>
import HeaderSection from '../components/header-section/header-section.vue'
import BreadcrumbExploreThreeSection from '../components/breadcrumb/breadcrumb-explore-three/breadcrumb-explore-three.vue'
import ExploreFourSection from '../components/explore/explore-four-section/explore-four-section.vue'
import FooterSection from '../components/footer-section/footer-section.vue'
import ModalSearchSection from '../components/modal/modal-search/modal-search.vue'
import ModalMenuSection from '../components/modal/modal-menu/modal-menu.vue'
import ScrollupSection from '../components/scrollup/scrollup.vue'

export default {
  name: 'ExploreThree',
  components: {
    HeaderSection,
    BreadcrumbExploreThreeSection,
    ExploreFourSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ScrollupSection
  }
}
</script>

<style>

</style>