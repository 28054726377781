<template>
    <section class="author-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-7">
                    <!-- Intro -->
                    <div class="intro text-center">
                        <span>Signup</span>
                        <h3 class="mt-3 mb-0">Create an Account</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    </div>
                    <!-- Item Form -->
                    <form class="item-form card no-hover">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mt-3">
                                    <input type="text" class="form-control" name="name" placeholder="Enter your Name" required="required">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mt-3">
                                    <input type="email" class="form-control" name="email" placeholder="Enter your Email" required="required">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mt-3">
                                    <input type="password" class="form-control" name="password" placeholder="Enter your Password" required="required">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mt-3">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                        <label class="form-check-label" for="inlineRadio1">I agree to <a href="#">Privacy Policy</a></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn w-100 mt-3 mt-sm-4" type="submit">Sign Up</button>
                            </div>
                            <div class="col-12">
                                <span class="d-block text-center mt-4">Already have an account? <a href="login">Login</a></span>
                            </div>
                            <div class="col-12">
                                <hr>
                                <div class="other-option">
                                    <span class="d-block text-center mb-4">Or</span>
                                    <!-- Social Icons -->
                                    <div class="social-icons d-flex justify-content-center">
                                        <a class="facebook" target="_blank" href="https://www.facebook.com/">
                                            <i class="fab fa-facebook-f"></i>
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                        <a class="twitter" target="_blank" href="https://twitter.com/">
                                            <i class="fab fa-twitter"></i>
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                        <a class="discord" target="_blank" href="https://discord.com/">
                                            <i class="fab fa-discord"></i>
                                            <i class="fab fa-discord"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>